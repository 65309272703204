/**
 * Copyright 2023 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

/**
 * util class that creates a common set of convenience functions to wrap
 * shared behavior of Advanced Markers and Markers.
 */

export type Marker = (
    | google.maps.Marker
    | google.maps.marker.AdvancedMarkerElement
) & {
    hasError?: boolean;
    hasWarning?: boolean;
};

export class MarkerUtils {
    public static isAdvancedMarkerAvailable(map: google.maps.Map): boolean {
        return (
            google.maps.marker &&
            map.getMapCapabilities().isAdvancedMarkersAvailable === true
        );
    }

    public static isAdvancedMarker(
        marker: Marker
    ): marker is google.maps.marker.AdvancedMarkerElement {
        return (
            google.maps.marker &&
            marker instanceof google.maps.marker.AdvancedMarkerElement
        );
    }

    public static getBxcId(marker: Marker) {
        if (this.isAdvancedMarker(marker)) {
            const id = parseInt(marker.content?.getAttribute('data-bxcid'));
            return isNaN(id) ? null : id;
        } else {
            return null;
        }
    }

    public static hasError(marker: Marker) {
        if (this.isAdvancedMarker(marker)) {
            return marker.content?.hasAttribute('data-error');
        } else {
            return false;
        }
    }

    public static hasWarning(marker: Marker) {
        if (this.isAdvancedMarker(marker)) {
            return marker.content?.hasAttribute('data-warning');
        } else {
            return false;
        }
    }

    public static hasMap(marker: Marker) {
        if (this.isAdvancedMarker(marker)) {
            return marker.map !== null;
        } else {
            return marker.getMap() != null;
        }
    }

    public static setMap(marker: Marker, map: google.maps.Map | null) {
        if (this.isAdvancedMarker(marker)) {
            if (marker.map == map) return;
            marker.map = map;
        } else {
            marker.setMap(map);
        }
    }

    public static getPosition(marker: Marker): google.maps.LatLng {
        // SuperClusterAlgorithm.calculate expects a LatLng instance so we fake it for Adv Markers
        if (this.isAdvancedMarker(marker)) {
            if (marker.position) {
                if (marker.position instanceof google.maps.LatLng) {
                    return marker.position;
                }
                // since we can't cast to LatLngLiteral for reasons =(
                if (
                    marker.position.lat != null &&
                    marker.position.lng != null
                ) {
                    return new google.maps.LatLng(
                        marker.position.lat,
                        marker.position.lng
                    );
                }
            }
            return new google.maps.LatLng(null);
        }
        return marker.getPosition();
    }

    public static getVisible(marker: Marker) {
        if (this.isAdvancedMarker(marker)) {
            /**
             * Always return true for Advanced Markers because the clusterer
             * uses getVisible as a way to count legacy markers not as an actual
             * indicator of visibility for some reason. Even when markers are hidden
             * Marker.getVisible returns `true` and this is used to set the marker count
             * on the cluster. See the behavior of Cluster.count
             */
            return true;
        }
        return marker.getVisible();
    }
}

export type ClusterStatusState = {
    inError: number;
    inWarning: number;
    inOk: number;
};

const keyToColorLookup: Record<string, string> = {
    inOk: '#00c853',
    inError: '#ff0000',
    inWarning: '#ffa500',
};

export const GetClusterIcon = (state: ClusterStatusState) => {
    const toRender = Object.entries(state).filter((kv) => kv[1] > 0);
    if (toRender.length === 2) {
        const [firstKey, firstCount] = toRender[0];
        const [secondKey, secondCount] = toRender[1];
        return `
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 240 240" width="70" height="70">
            <defs>
                <symbol id="circle">
                    <circle cx="120" cy="120" opacity=".6" r="70"/>
                    <circle cx="120" cy="120" opacity=".4" r="90"/>
                    <circle cx="120" cy="120" opacity=".3" r="110"/>
                </symbol>
            </defs>
            <use href="#circle" x="0" y="0" style="transform:scale(0.65)" fill="${keyToColorLookup[firstKey]}"/>
            <use href="#circle" x="130" y="130" style="transform:scale(0.65)" fill="${keyToColorLookup[secondKey]}"/>
            <text x="32%" y="35%" style="fill:#000" text-anchor="middle" font-size="40" font-weight="bold" dominant-baseline="middle" font-family="roboto,arial,sans-serif">${firstCount}</text>
            <text x="67%" y="69%" style="fill:#000" text-anchor="middle" font-size="40" font-weight="bold" dominant-baseline="middle" font-family="roboto,arial,sans-serif">${secondCount}</text>
        </svg>
        `;
    } else if (toRender.length === 3) {
        const [firstKey, firstCount] = toRender[0];
        const [secondKey, secondCount] = toRender[1];
        const [thirdKey, thirdCount] = toRender[2];
        return `
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 240 240" width="90" height="90">
            <defs>
                <symbol id="circle">
                    <circle cx="120" cy="120" opacity=".6" r="70"/>
                    <circle cx="120" cy="120" opacity=".4" r="90"/>
                    <circle cx="120" cy="120" opacity=".3" r="110"/>
                </symbol>
            </defs>
            <use href="#circle" x="0" y="0" style="transform:scale(0.57)" fill="${keyToColorLookup[firstKey]}"/>
            <use href="#circle" x="180" y="0" style="transform:scale(0.57)" fill="${keyToColorLookup[secondKey]}" />
            <use href="#circle" x="90" y="180" style="transform:scale(0.57)" fill="${keyToColorLookup[thirdKey]}"/>
            <text x="28%" y="30%" style="fill:#000" text-anchor="middle" font-size="30" font-weight="bold" dominant-baseline="middle" font-family="roboto,arial,sans-serif">${firstCount}</text>
            <text x="71%" y="30%" style="fill:#000;" text-anchor="middle" font-size="30" font-weight="bold" dominant-baseline="middle" font-family="roboto,arial,sans-serif">${secondCount}</text>
            <text x="50%" y="72%" style="fill:#000;" text-anchor="middle" font-size="30" font-weight="bold" dominant-baseline="middle" font-family="roboto,arial,sans-serif">${thirdCount}</text>
        </svg>
        `;
    } else {
        const [firstKey, firstCount] = toRender[0];
        return `
        <svg fill="${keyToColorLookup[firstKey]}" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 240 240" width="50" height="50">
          <circle cx="120" cy="120" opacity=".6" r="70" />
          <circle cx="120" cy="120" opacity=".4" r="90" />
          <circle cx="120" cy="120" opacity=".3" r="110" />
         <text x="50%" y="52%" style="fill:#000" text-anchor="middle" font-size="55" font-weight="bold" dominant-baseline="middle" font-family="roboto,arial,sans-serif">${firstCount}</text>
        </svg>
        `;
    }
};
