/**
 * Copyright 2021 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import {
    Algorithm,
    AlgorithmOptions,
    SuperClusterAlgorithm,
    SuperClusterViewportAlgorithm,
} from './algorithms';
import { ClusterStats, DefaultRenderer, Renderer } from './renderer';
import { Cluster } from './cluster';
import { OverlayViewSafe } from './overlay-view-safe';
import { MarkerUtils, Marker } from './marker-utils';

export type onClusterClickHandler = (
    event: google.maps.MapMouseEvent,
    cluster: Cluster,
    map: google.maps.Map
) => void;
export interface MarkerClustererOptions {
    markers?: Marker[];
    /**
     * An algorithm to cluster markers. Default is {@link SuperClusterAlgorithm}. Must
     * provide a `calculate` method accepting {@link AlgorithmInput} and returning
     * an array of {@link Cluster}.
     */
    algorithm?: Algorithm;
    algorithmOptions?: AlgorithmOptions;
    map?: google.maps.Map | null;
    /**
     * An object that converts a {@link Cluster} into a `google.maps.Marker`.
     * Default is {@link DefaultRenderer}.
     */
    renderer?: Renderer;
    onClusterClick?: onClusterClickHandler;
}

export enum MarkerClustererEvents {
    CLUSTERING_BEGIN = 'clusteringbegin',
    CLUSTERING_END = 'clusteringend',
    CLUSTER_CLICK = 'click',
    SUBSCRIBE_TO = 'subscribeTo',
    UNSUBSCRIBE = 'unsubscribe',
}

export const defaultOnClusterClickHandler: onClusterClickHandler = (
    _: google.maps.MapMouseEvent,
    cluster: Cluster,
    map: google.maps.Map
): void => {
    map.fitBounds(cluster.bounds);
};
/**
 * MarkerClusterer creates and manages per-zoom-level clusters for large amounts
 * of markers. See {@link MarkerClustererOptions} for more details.
 *
 */
export class MarkerClusterer extends OverlayViewSafe {
    /** @see {@link MarkerClustererOptions.onClusterClick} */
    public onClusterClick: onClusterClickHandler;
    /** @see {@link MarkerClustererOptions.algorithm} */
    protected algorithm: Algorithm;
    protected clusters: Cluster[];
    protected previousZoom: number | undefined;
    protected markers: Marker[];
    /** @see {@link MarkerClustererOptions.renderer} */
    protected renderer: Renderer;
    /** @see {@link MarkerClustererOptions.map} */
    protected map: google.maps.Map | null;
    protected idleListener: google.maps.MapsEventListener;
    protected subscribedMarkers: Set<Number>;

    constructor({
        map,
        markers = [],
        algorithmOptions = {},
        algorithm = new SuperClusterViewportAlgorithm({
            maxZoom: 12,
            minPoints: 1,
        }),
        renderer = new DefaultRenderer(),
        onClusterClick = defaultOnClusterClickHandler,
    }: MarkerClustererOptions) {
        super();
        this.markers = [];
        this.subscribedMarkers = new Set<Number>();
        this.addMarkers(markers, true);
        this.clusters = [];

        this.algorithm = algorithm;
        this.renderer = renderer;

        this.onClusterClick = onClusterClick;

        if (map) {
            this.setMap(map);
            this.previousZoom = map.getZoom();
        }
    }

    // public markersChanged(): void {
    //     // @ts-ignore
    //     const mapObjects = window.blazorGoogleMaps?.objectManager?.mapObjects;
    //     const objects = Object.entries(mapObjects).map((entry) => entry[1]);
    //     const markers = [];
    //     for (const entry of objects) {
    //         // @ts-ignore
    //         if (MarkerUtils.isAdvancedMarker(entry)) {
    //             markers.push(entry);
    //         }
    //     }

    //     this.addMarkers(markers);
    // }

    public addMarker(marker: Marker, noDraw?: boolean): void {
        if (this.markers.includes(marker)) {
            return;
        }

        this.markers.push(marker);
        MarkerUtils.setMap(marker, null);

        if (!noDraw) {
            this.render();
        }
    }

    // NOTE: This is "syncMarkers"
    public addMarkers(markers: Marker[], noDraw?: boolean): void {
        // Remove markers not in the new array
        const toUnsubscribe: Number[] = [];
        const toRemove: Marker[] = [];
        this.markers.forEach((marker) => {
            const bxcId = MarkerUtils.getBxcId(marker);
            const isExisting = markers.find(
                (newMarker) => MarkerUtils.getBxcId(newMarker) == bxcId
            );
            if (isExisting) return;
            toRemove.push(marker);
            if (this.subscribedMarkers.delete(bxcId)) {
                toUnsubscribe.push(bxcId);
            }
        });

        this.removeMarkers(toRemove, true);

        // Add new markers
        markers.forEach((marker) => {
            this.addMarker(marker, true);
        });

        if (!noDraw) {
            this.render();
        }

        if (toUnsubscribe.length > 0) {
            requestAnimationFrame(() => {
                google.maps.event.trigger(
                    this,
                    MarkerClustererEvents.UNSUBSCRIBE,
                    // @ts-ignore
                    toUnsubscribe
                );
            });
        }
    }

    public removeMarker(marker: Marker, noDraw?: boolean): boolean {
        const index = this.markers.indexOf(marker);

        if (index === -1) {
            // Marker is not in our list of markers, so do nothing:
            return false;
        }

        MarkerUtils.setMap(marker, null);
        this.markers.splice(index, 1); // Remove the marker from the list of managed markers

        if (!noDraw) {
            this.render();
        }

        return true;
    }

    public removeMarkers(markers: Marker[], noDraw?: boolean): boolean {
        let removed = false;

        markers.forEach((marker) => {
            removed = this.removeMarker(marker, true) || removed;
        });

        if (removed && !noDraw) {
            this.render();
        }

        return removed;
    }

    public clearMarkers(noDraw?: boolean): void {
        const toUnsubscribe: Number[] = [];
        this.markers.forEach((marker) => {
            MarkerUtils.setMap(marker, this.map); // Reset map
            const bxcId = MarkerUtils.getBxcId(marker);
            if (bxcId && this.subscribedMarkers.has(bxcId)) {
                toUnsubscribe.push(bxcId);
            }
        });

        this.markers.length = 0;
        this.subscribedMarkers.clear();

        if (!noDraw) {
            this.render();
        }

        if (toUnsubscribe.length > 0 && !noDraw) {
            requestAnimationFrame(() => {
                google.maps.event.trigger(
                    this,
                    MarkerClustererEvents.UNSUBSCRIBE,
                    // @ts-ignore
                    toUnsubscribe
                );
            });
        }
    }

    /**
     * Recalculates and draws all the marker clusters.
     */
    public redraw(): void {
        this.render(true);
    }

    /**
     * Recalculates and draws all the marker clusters.
     */
    public render(force = false): void {
        const map = this.getMap();
        if (map instanceof google.maps.Map && map.getProjection()) {
            google.maps.event.trigger(
                this,
                MarkerClustererEvents.CLUSTERING_BEGIN,
                this
            );
            const { clusters, changed } = this.algorithm.calculate({
                markers: this.markers,
                map,
                mapCanvasProjection: this.getProjection(),
            });
            const currentZoom = map.getZoom();
            // allow algorithms to return flag on whether the clusters/markers have changed
            if (changed || changed == undefined || force) {
                // Accumulate the markers of the clusters composed of a single marker.
                // Those clusters directly use the marker.
                // Clusters with more than one markers use a group marker generated by a renderer.
                const singleMarker = new Set<Marker>();
                for (const cluster of clusters) {
                    if (!cluster.isCluster) {
                        singleMarker.add(cluster.markers[0]);
                    }
                }
                const toUnsubscribe: number[] = [];
                const groupMarkers: Marker[] = [];
                for (const cluster of this.clusters) {
                    if (cluster.marker == null) {
                        continue;
                    }
                    if (!cluster.isCluster) {
                        if (!singleMarker.has(cluster.marker)) {
                            // The marker:
                            // - was previously rendered because it is from a cluster with 1 marker,
                            // - should no more be rendered as it is not in singleMarker.
                            const bxcId = MarkerUtils.getBxcId(cluster.marker);
                            if (bxcId && this.subscribedMarkers.has(bxcId)) {
                                this.subscribedMarkers.delete(bxcId);
                                toUnsubscribe.push(bxcId);
                            }
                            MarkerUtils.setMap(cluster.marker, null);
                        }
                    } else {
                        groupMarkers.push(cluster.marker);
                    }
                }
                // reset visibility of markers and clusters
                if (toUnsubscribe.length > 0) {
                    google.maps.event.trigger(
                        this,
                        MarkerClustererEvents.UNSUBSCRIBE,
                        // @ts-ignore
                        toUnsubscribe
                    );
                }

                this.clusters = clusters;
                this.previousZoom = currentZoom;
                this.renderClusters();

                // Delayed removal of the markers of the former groups.
                requestAnimationFrame(() => {
                    groupMarkers.forEach((marker) =>
                        MarkerUtils.setMap(marker, null)
                    );
                });
            }
            google.maps.event.trigger(
                this,
                MarkerClustererEvents.CLUSTERING_END,
                this
            );
        }
    }

    public onAdd(): void {
        this.idleListener = this.getMap().addListener(
            'idle',
            this.render.bind(this)
        );
        this.render();
    }

    public onRemove(): void {
        google.maps.event.removeListener(this.idleListener);
        this.reset();
    }

    protected reset(): void {
        this.markers.forEach((marker) => MarkerUtils.setMap(marker, null));
        this.clusters.forEach((cluster) => cluster.delete());
        this.clusters = [];
    }

    protected renderClusters(): void {
        // generate stats to pass to renderers
        const stats = new ClusterStats(this.markers, this.clusters);
        const map = this.getMap() as google.maps.Map;
        const toSubscribe: number[] = [];
        this.clusters.forEach((cluster) => {
            if (!cluster.isCluster) {
                cluster.marker = cluster.markers[0];
                const bxcId = MarkerUtils.getBxcId(cluster.marker);
                if (bxcId && !this.subscribedMarkers.has(bxcId)) {
                    this.subscribedMarkers.add(bxcId);
                    toSubscribe.push(bxcId);
                }
            } else {
                cluster.marker = this.renderer.render(cluster, stats, map);
                // Make sure all individual markers are removed from the map.
                cluster.markers.forEach((marker) =>
                    MarkerUtils.setMap(marker, null)
                );
                cluster.marker.addListener(
                    'click',
                    /* istanbul ignore next */
                    (event: google.maps.MapMouseEvent) => {
                        google.maps.event.trigger(
                            this,
                            MarkerClustererEvents.CLUSTER_CLICK,
                            cluster
                        );
                        defaultOnClusterClickHandler(event, cluster, map);
                    }
                );
            }
            MarkerUtils.setMap(cluster.marker, map);
        });
        if (toSubscribe.length > 0) {
            google.maps.event.trigger(
                this,
                MarkerClustererEvents.SUBSCRIBE_TO,
                // @ts-ignore
                toSubscribe
            );
        }
    }
}
